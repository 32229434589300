<template>
  <div class="page">
    <v-container fluid>
      <avp-breadcrumbs></avp-breadcrumbs>
      <v-form ref="form">
        <v-row class="d-flex justify-center mt-3">
          <avp-select-lotacao
            :cols="3"
            :required="!form.filters.cpf"
            v-model="form.filters"
          ></avp-select-lotacao>
          <v-col cols="2">
            <v-text-field
              label="CPF"
              v-mask="'###########'"
              v-model="form.filters.cpf"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              label="Matrícula"
              v-mask="'###########'"
              v-model="form.filters.matricula"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <avp-select-ano-semestre
            all
            :cols="4"
            :sufix="'da Comprovação'"
            v-model="form.filters"
          ></avp-select-ano-semestre>
          <avp-select-situacao
            :cols="3"
            v-model="form.filters"
          ></avp-select-situacao>
        </v-row>
        <div class="text-center">
          <v-col cols="12">
            <avp-tooltip
              :text="'Buscar'"
              :icon="'mdi-magnify'"
              @click="buscarComprovacoes()"
            ></avp-tooltip>
            <avp-tooltip
              :text="'Limpar'"
              :icon="'mdi-eraser'"
              @click="$resetForm()"
            ></avp-tooltip>
          </v-col>
        </div>
      </v-form>
      <v-data-table
        class="mt-5"
        :headers="headers"
        :items="form.lists.listValidacoes"
        :items-per-page="10"
        :footer-props="$footerProps"
      >
        <template v-slot:[`item.nomeProfissional`]="{ item }">
          {{ item.nomeProfissional }}
        </template>
        <template v-slot:[`item.cpfProfissional`]="{ item }">
          {{ item.cpfProfissional }}
        </template>
        <template v-slot:[`item.matricula`]="{ item }">
          {{ item.matricula }}
        </template>
        <template v-slot:[`item.ano`]="{ item }">
          {{ item.ano }}
        </template>
        <template v-slot:[`item.semestre`]="{ item }">
          {{ item.semestre }}
        </template>
        <template v-slot:[`item.nomeCapacitacao`]="{ item }">
          {{ item.nomeCapacitacao }}
        </template>
        <template v-slot:[`item.situacao`]="{ item }">
          {{ item.situacao }}
        </template>
        <template v-slot:[`item.acoes`]="{ item }">
          <avp-tooltip
            v-if="
              !item.flgVisualizar &&
              !foraDoPeriodoDeValidacao &&
              item.cicloAtivo
            "
            :text="
              item.flgPossuiValidacao
                ? 'Editar Validação'
                : 'Validar/Não Validar'
            "
            :icon="item.flgPossuiValidacao ? 'mdi-pencil' : 'mdi-account-edit'"
            :small="true"
            @click="openDialogValidarComprovacao(item)"
          ></avp-tooltip>
          <avp-tooltip
            :text="'Visualizar Comprovação'"
            :icon="'mdi-eye-outline'"
            :small="true"
            @click="openDialogVisualizarComprovacao(item)"
          ></avp-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <ValidarComprovacaoAcaoDialog
      :visible="dialogValidarComprovacao.visible"
      :content="dialogValidarComprovacao.content"
      :edit="dialogValidarComprovacao.edit"
      @onClose="dialogValidarComprovacao.close()"
      @onSelectValue="buscarComprovacoes()"
    ></ValidarComprovacaoAcaoDialog>
    <VisualizarComprovacaoAcaoDialog
      :visible="dialogVisualizarComprovacao.visible"
      :content="dialogVisualizarComprovacao.content"
      @onClose="dialogVisualizarComprovacao.close()"
    ></VisualizarComprovacaoAcaoDialog>
  </div>
</template>

<script>
import { Dialog } from "~/commons/classes";
import ValidarComprovacaoAcaoDialog from "./dialogs/ValidarComprovacaoAcaoDialog.vue";
import VisualizarComprovacaoAcaoDialog from "./dialogs/VisualizarComprovacaoAcaoDialog.vue";

export default {
  name: "ListarValidacoesComprovacaoAcaoDeCapacitacaoView",
  components: {
    ValidarComprovacaoAcaoDialog,
    VisualizarComprovacaoAcaoDialog,
  },
  data: () => ({
    dialogValidarComprovacao: Dialog.Build(),
    dialogVisualizarComprovacao: Dialog.Build(),
    form: {
      filters: {
        matricula: null,
        codigoLotacao: null,
        cpf: null,
        ano: null,
        semestre: null,
        situacao: null,
      },
      lists: {
        listValidacoes: [],
      },
    },
    headers: [
      { text: "Profissional", align: "center", value: "nomeProfissional" },
      { text: "CPF", align: "center", value: "cpfProfissional" },
      { text: "Matrícula", align: "center", value: "matricula" },
      { text: "Ano da Comprovação", align: "center", value: "ano" },
      { text: "Semestre da Comprovação", align: "center", value: "semestre" },
      {
        text: "Nome da Capacitação",
        align: "center",
        value: "nomeCapacitacao",
      },
      { text: "Situação", align: "center", value: "situacao" },
      { text: "Ações", align: "center", value: "acoes", sortable: false },
    ],
    foraDoPeriodoDeValidacao: false,
  }),
  methods: {
    openDialogValidarComprovacao(item) {
      this.$verificarPeriodoValidoParaValidar().then(({ data }) => {
        this.foraDoPeriodoDeValidacao = data.flgForaDoPeriodoDeValidacao;
        if (data.flgForaDoPeriodoDeValidacao) {
          this.$swal({
            text: `Período de validacao de comprovação da ação de capacitação inválido!`,
            type: "error",
            confirmButtonText: `Confirmar`,
          });
        } else {
          this.$visualizarDadosComprovacaoParaValidar({
            params: { comprovacaoId: item.id },
          }).then(({ data }) =>
            this.dialogValidarComprovacao.open(
              data.comprovacao,
              item.flgPossuiValidacao
            )
          );
        }
      });
    },
    openDialogVisualizarComprovacao(item) {
      this.$visualizarDadosComprovacaoParaValidar({
        params: { comprovacaoId: item.id },
      }).then(({ data }) =>
        this.dialogVisualizarComprovacao.open(data.comprovacao, true)
      );
    },
    buscarComprovacoes() {
      if (this.$validarFormulario()) {
        this.$buscarComprovacoesParaValidar(
          this.$montarParamsFilters(this.form.filters)
        ).then(({ data }) => {
          this.form.lists.listValidacoes = data.comprovacoes;
          if (!data.comprovacoes.length && this.form.filters.cpf) {
            this.$notifyInfo(
              "Registro da Ação de Capacitação não localizado. Verifique se ele pertence a sua Lotação!"
            );
          }
        });
      }
    },
  },
  mounted() {
    this.form.filters.situacao = 3;
  },
};
</script>